export const i9FormFields = [
    {
        name: 'employer_title',
        label: 'Employer Title',
    },
    {
        name: 'employer_name',
        label: 'Employer Organisation Name'
    },
    {
        name: 'appointment_with',
        label: 'Appointment With ( authorized_representative or hiring_manager )'
    },
    {
        name: 'terms_and_conditions_url',
        label: 'Terms and Condition URL'
    },
    {
        name: 'privacy_policy_url',
        label: 'Privacy Policy URL'
    }
]

export const everifyFormFields = [
    {
        name: 'everify_account_id',
        label: 'E-Verify Account ID'
    },
    {
        name: 'everify_case_creator_name',
        label: 'E-Verify Case Creator Name'
    },
    {
        name: 'everify_case_creator_email',
        label: 'E-Verify Case Creator Email'
    },
    {
        name: 'everify_case_creator_phone',
        label: 'E-Verify Case Creator Phone'
    },
]
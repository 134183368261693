import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import ClientService from "../../services/ClientService";
import {useFormControls} from "../common/FormControls";
import {Grid, Button, Snackbar, TextField, Theme, Alert as SnackAlert} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import SaveIcon from '@mui/icons-material/Save';
import {createStyles, makeStyles} from "@mui/styles";
import {Switch, FormControlLabel} from "@mui/material";
import {i9FormFields, everifyFormFields} from "../common/UiFieldList";
import {BrandResponse} from "../../models/BrandResponse";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: 8,
                width: '95%',
            },
        },
        start_date: {
            width: '48%', paddingRight: '1%'
        },
        end_date: {
            width: '48%', paddingLeft: '1%'
        }
    })
);

const emptyInitialFormValues = {
    name: "",
    formSubmitted: false,
    success: false,
    startDate: new Date().toISOString().substring(0, 10) + "T00:00:00+00:00",
    endDate: new Date().toISOString().substring(0, 10) + "T00:00:00+00:00",
    allowedEmailDomains: "",
    i9Config: null,
    hiringManagers: []
}

const formFields = [
    {
        name: 'name',
        label: 'Brand Name',
    },
    {
        name: 'contactName',
        label: 'Contact Name'
    },
    {
        name: 'contactEmail',
        label: 'Contact Email'
    },
    {
        name: 'contactPhoneNumber',
        label: 'Contact Phone Number'
    },
    {
        name: 'allowedEmailDomains',
        label: 'Allowed Email Domains'
    }
]

const CreateBrandForm = ({clientId, updateTarget}: { clientId: string; updateTarget?: BrandResponse }) => {
    const classes = useStyles();
    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
    const [isI9Brand, setIsI9Brand] = React.useState<boolean>(!!updateTarget?.i9Config);
    const [hiringManagerName, setHiringManagerName] = React.useState('');
    const [hiringManagerLastName, setHiringManagerLastName] = React.useState('');
    const [hiringManagerEmail, setHiringManagerEmail] = React.useState('');
    const [hiringManagerPhone, setHiringManagerPhone] = React.useState('');
    const [hiringManagerTitle, setHiringManagerTitle] = React.useState('');

    const navigate = useNavigate();

    const initialFormValues = updateTarget ? {
        ...updateTarget,
        formSubmitted: false,
        success: false,
        i9Config: updateTarget.i9Config ? {
            ...updateTarget.i9Config,
            authorized_representative: JSON.stringify(updateTarget.i9Config.authorized_representative, null, 2)
        } : undefined,
        hiringManagers: updateTarget.hiringManagers ? updateTarget.hiringManagers : [{}]
    } : emptyInitialFormValues;

    const {
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        errors,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
        values
    } = useFormControls({
        initialFormValues: initialFormValues,
        formAction: ({data, onSuccess, onFailure}) => {
            try {
                const request = {
                    ...data,
                    hiringManagers: [
                        {
                            name: hiringManagerName,
                            lastName: hiringManagerLastName,
                            email: hiringManagerEmail,
                            phone: hiringManagerPhone,
                            title: hiringManagerTitle
                        }        
                    ],
                    ...(!data.i9Config ? {} : {
                        i9Config: {
                            ...data.i9Config,
                            authorized_representative: JSON.parse(
                                data.i9Config.authorized_representative
                            )
                        }
                    })
                }

                if (updateTarget) {
                    ClientService.updateBrand(request, clientId, updateTarget.id, onSuccess, onFailure);
                } else {
                    ClientService.createBrand(request, clientId, onSuccess, onFailure);
                }
            } catch (e) {
                alert("Please ensure Authorized Representative field has a valid JSON.")
            }
        },
        onSuccess: () => {
            if (updateTarget) {
                navigate(`/clients/${clientId}/brands/${updateTarget.id}`);
            } else {
                navigate(`/clients/${clientId}`);
            }
        }
    });

    const handleStartDateChange = (date: any) => {
        setSelectedStartDate(date);
        handleInputValue({target: {name: "startDate", value: date.toISOString().substring(0, 10) + "T00:00:00+00:00"}});
    };

    const handleEndDateChange = (date: any) => {
        setSelectedEndDate(date);
        handleInputValue({target: {name: "endDate", value: date.toISOString().substring(0, 10) + "T00:00:00+00:00"}});
    };

    const handleI9ValueChange = (e: any) => {
        const {name, value} = e.target;
        handleInputValue({
            target: {
                name: "i9Config",
                value: {
                    ...values.i9Config,
                    [name]: value,
                }
            }
        });
    }

    const handleI9ToggleChange = (e: any) => {
        handleI9ValueChange({
            target: {
                name: e.target.name,
                value: e.target.checked,
            }
        })
    }

    const handleIncludeI9ConfigToggle = useCallback(() => {
        if (updateTarget) {
            return
        }

        handleInputValue(isI9Brand ? {
            target: {
                name: "i9Config",
                value: null
            }
        } : {
            target: {
                name: "i9Config",
                value: {
                    everifyEnabled: false,
                    employer_title: "",
                    employer_name: "",
                    appointment_with: "",
                    terms_and_conditions_url: "",
                    privacy_policy_url: "",
                    authorized_representative: {},
                    is_employer: false,
                }
            }
        });

        setIsI9Brand(!isI9Brand);
    }, [isI9Brand, handleInputValue, updateTarget]);

    return <div id={clientId}>
        <form className={classes.root} autoComplete="off" onSubmit={handleFormSubmit}>
            <div>
                {formFields.map((field, idx) => {
                    return (
                        <TextField id={field.name + "TextField"} name={field.name} key={idx} label={field.label}
                                   required={true} type="text" fullWidth={true} value={values[field.name]}
                                   onChange={handleInputValue} onBlur={handleInputValue} variant="standard"
                                   {...(errors[field.name] && {error: true, helperText: errors[field.name]})} />
                    );
                })
                }
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isI9Brand}
                            disabled={!!updateTarget}
                            onChange={handleIncludeI9ConfigToggle}
                            name="includeI9Config"
                            color="primary"
                        />
                    }
                    label="Include i9 Config"
                />
                {isI9Brand &&
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={values.i9Config?.everifyEnabled}
                                    onChange={handleI9ToggleChange}
                                    name="everifyEnabled"
                                    color="primary"
                                />
                            }
                            label="E-Verify Enabled"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={values.i9Config?.is_employer}
                                    onChange={handleI9ToggleChange}
                                    name="is_employer"
                                    color="primary"
                                />
                            }
                            label="Is Employer Flow"
                        />

                        {
                            i9FormFields.map((field, idx) =>
                                <TextField id={field.name + "TextField"} name={field.name} key={idx} label={field.label}
                                           required={true} type="text" fullWidth={true}
                                           value={values?.i9Config[field.name]}
                                           onChange={handleI9ValueChange} onBlur={handleI9ValueChange}
                                           variant="standard"
                                />)
                        }

                        {
                            values.i9Config?.everifyEnabled && everifyFormFields.map((field, idx) =>
                                <TextField id={field.name + "TextField"} name={field.name} key={idx} label={field.label}
                                           required={true} type="text" fullWidth={true} value={values?.i9Config[field.name]}
                                           onChange={handleI9ValueChange} onBlur={handleI9ValueChange} variant="standard"
                                />)
                        }

                        {isI9Brand && <TextField id={"hiringManagerNameTextField"} name="hiringManagerLastName"
                                                 label="Hiring Manager Last Name"
                                                 required={true} type="text" fullWidth={true}
                                                 defaultValue={values?.hiringManagers.length !== 0 ? values?.hiringManagers[0].lastName : hiringManagerLastName}
                                                 onChange={(e) => setHiringManagerLastName(e.target.value)} onBlur={(e) => setHiringManagerLastName(e.target.value)}
                                                 variant="standard"
                        />}

                        {isI9Brand && <TextField id={"hiringManagerNameTextField"} name="hiringManagerName"
                                                 label="Hiring Manager First Name"
                                                 required={true} type="text" fullWidth={true}
                                                 defaultValue={values?.hiringManagers.length !== 0 ? values?.hiringManagers[0].name : hiringManagerName}
                                                 onChange={(e) => setHiringManagerName(e.target.value)} onBlur={(e) => setHiringManagerName(e.target.value)}
                                                 variant="standard"
                        />}

                        {isI9Brand && <TextField id={"hiringManagerEmailTextField"} name="hiringManagerEmail"
                                                 label="Hiring Manager Email"
                                                 required={true} type="text" fullWidth={true}
                                                 defaultValue={values?.hiringManagers.length !== 0 ? values?.hiringManagers[0].email : hiringManagerEmail}
                                                 onChange={(e) => setHiringManagerEmail(e.target.value)} onBlur={(e) => setHiringManagerEmail(e.target.value)}
                                                 variant="standard"
                        />}

                        {isI9Brand && <TextField id={"hiringManagerPhoneTextField"} name="hiringManagerPhone"
                                                 label="Hiring Manager Phone"
                                                 required={true} type="text" fullWidth={true}
                                                 defaultValue={values?.hiringManagers.length !== 0 ? values?.hiringManagers[0].phone : hiringManagerPhone}
                                                 onChange={(e) => setHiringManagerPhone(e.target.value)} onBlur={(e) => setHiringManagerPhone(e.target.value)}
                                                 variant="standard"
                        />}

                        {isI9Brand && <TextField id={"hiringManagerPhoneTextField"} name="hiringManagerTitle"
                                                 label="Hiring Manager Title"
                                                 required={true} type="text" fullWidth={true}
                                                 defaultValue={values?.hiringManagers.length !== 0 ? values?.hiringManagers[0].title : hiringManagerTitle}
                                                 onChange={(e) => setHiringManagerTitle(e.target.value)} onBlur={(e) => setHiringManagerTitle(e.target.value)}
                                                 variant="standard"
                        />}

                        <TextField id={"repTextField"} name={"authorized_representative"}
                                   label={"Authorized Representative (JSON)"}
                                   value={values?.i9Config.authorized_representative}
                                   required={true} type="text" fullWidth={true}
                                   multiline
                                   rows={5}
                                   onChange={handleI9ValueChange} onBlur={handleI9ValueChange} variant="outlined"
                        />
                    </div>
                }

            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="space-between">
                    <DatePicker className={classes.start_date}
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        required: true,
                                        id: "startDate",
                                        name: "startDate"
                                    }
                                }}
                                label="Start Date"
                                format={"yyyy-MM-dd"}
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                    />
                    <DatePicker className={classes.end_date}
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        required: true,
                                        id: "endDate",
                                        name: "endDate"
                                    }
                                }}
                                label="End Date"
                                format={"yyyy-MM-dd"}
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                    />
                </Grid>
            </LocalizationProvider>
            <br/>
            <div>
                <Button id="save" color="primary" variant="contained" startIcon={<SaveIcon/>} type="submit"
                        disabled={!formIsValid()}>Save</Button>
            </div>
        </form>
        <div id='snackbar'>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleSuccessOnClose} severity={"success"}>
                    Successfully created brand {values.name}
                </SnackAlert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleFailureOnClose} severity={"error"}>
                    Could not create brand
                </SnackAlert>
            </Snackbar>
        </div>
    </div>
}

export default CreateBrandForm;
